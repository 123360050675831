import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const Tabs = EgretLoadable({
  loader: () => import("./Tabs")
});
const ViewComponent = withTranslation()(Tabs);
const ResultUnit = EgretLoadable({
  loader: () => import("./ResultUnit")
});
const ResultUnitComponent = withTranslation()(ResultUnit);
const CheckPointRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"report_result/check_point",
    exact: true,
    component: ViewComponent
  },
  {
    path:  ConstantList.ROOT_PATH+"report_result/result_unit",
    exact: true,
    component: ResultUnitComponent
  }
];

export default CheckPointRoutes;